import styled, { ThemedStyledProps } from 'styled-components';
import { media } from '@sizeid/commons/utils/styles';
import { key } from 'styled-theme';

export default styled.ul<{
  imageSize?: number;
  count: number;
  compact?: boolean;
}>`
  display: flex;
  padding: 0;
  margin-bottom: 0;
  margin-top: 0;

  li {
    width: ${props => 100 / props.count}%;
    padding: 0 5%;
  }

  svg,
  img {
    width: ${props => props.imageSize || 150}px;
    height: ${props => props.imageSize || 150}px;
  }

  .feature-image {
    width: initial;
    height: 120px;
    max-width: 100%;
    border-radius: 10px;
    box-shadow: ${key('outlines.shadow.default')};
  }

  ${props => (props.imageSize ? 'h3 { margin-top: 10px; }' : '')};

  ${props =>
    props.compact
      ? `
    p {
      margin: 10px 0 0;
    }

    h3 {
      margin-bottom: 10px;
    }
  `
      : ''};

  ${media.lessThan('760px')`
    flex-direction: column;

    li {
      width: auto;
    }

    ${(props: ThemedStyledProps<{ imageSize?: number }, unknown>) => `
      svg, img {
        width: ${(props.imageSize || 150) / 2}px;
        height: ${(props.imageSize || 150) / 2}px;
      }
    `}
  `};
`;
