import styled from 'styled-components';
import { media } from '@sizeid/commons/utils/styles';

export default styled.li`
  list-style: none;
  padding: 10px 0;
  margin-top: 40px;
  overflow: hidden;

  ${media.moreThan('sm')`
    padding: 25px 50px;
    overflow: visible;
  `};

  h3 {
    margin-top: 0;
  }

  p {
    margin-bottom: 0;
  }
`;
