import React from 'react';
import ImageZoom from 'react-medium-image-zoom';
import { nlBr } from '@sizeid/commons/utils/strings';
import { Pictogram } from '@sizeid/commons/components/SvgImage';

import H3 from 'components/typo/Heading/H3';
import P from 'components/typo/P';
import { withTranslation } from 'lib/i18n';

import FeaturesWrap from './styled/FeaturesWrap';
import Feature from './styled/Feature';
import { FeaturesProps } from './types';

const Features: React.FC<FeaturesProps> = props => (
  <FeaturesWrap
    count={props.keys.length}
    imageSize={props.imageSize}
    compact={props.compact}
  >
    {props.keys.map(key => (
      <Feature key={key} {...props.aos}>
        {props.illustrations ? (
          <img src={props.illustrations[key]} alt="" />
        ) : (
          <Pictogram image={key} size={`${props.imageSize || 145}px`} />
        )}

        <H3>
          {nlBr(props.t(`${props.base}.${key}.${props.titleKey || 'title'}`))}
        </H3>

        {props.images && (
          <ImageZoom>
            <img
              src={props.images[key]}
              className="feature-image"
              alt="The UI for the feature"
              width="100%"
            />
          </ImageZoom>
        )}

        <P>{props.t(`${props.base}.${key}.${props.textKey || 'text'}`)}</P>
        {props.subtitleKey && (
          <P dark>{props.t(`${props.base}.${key}.${props.subtitleKey}`)}</P>
        )}
      </Feature>
    ))}
  </FeaturesWrap>
);

export default withTranslation('pages')(Features);
